exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-copyright-js": () => import("./../../../src/pages/copyright.js" /* webpackChunkName: "component---src-pages-copyright-js" */),
  "component---src-pages-head-script-js": () => import("./../../../src/pages/HeadScript.js" /* webpackChunkName: "component---src-pages-head-script-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-monetag-banner-ad-js": () => import("./../../../src/pages/MonetagBannerAd.js" /* webpackChunkName: "component---src-pages-monetag-banner-ad-js" */),
  "component---src-pages-mp-3-music-js": () => import("./../../../src/pages/mp3-music.js" /* webpackChunkName: "component---src-pages-mp-3-music-js" */),
  "component---src-pages-share-buttons-js": () => import("./../../../src/pages/ShareButtons.js" /* webpackChunkName: "component---src-pages-share-buttons-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */)
}

